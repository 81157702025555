/** @format */

import React, { ReactNode, useEffect, useMemo } from "react";
import { TooltipProps, XAxisProps } from "recharts";
import { isNumber, sortBy, findIndex } from "lodash-es";

import {
  useFloating,
  offset,
  flip,
  shift,
  FloatingPortal,
  autoUpdate,
} from "@floating-ui/react";
import {
  ChartLayerSettings,
  CustomReferenceAreaProps,
  DataItem,
  TooltipTitleFormatter,
  TooltipValueFormatter,
} from "./types";
import { defaultColor } from "./utils";
import { LabelWithDescription } from "@blocks/MarketsCard";
import { getLocaleNumber, getLocaleUSDCurrency } from "src/lib/utils";

type TooltipItemData = {
  /** Optional item ID (for keys) */
  id?: string;
  /**
   * Optional svg styles for the colored rectangel in tooltip item (default: black rect)
   */
  svgStyles: {
    fill?: string;
    stroke?: string;
    strokeDasharray?: string;
    strokeWidth?: string;
  };
  /**
   * Label text to display for a datapoint value.
   */
  label: string;
  /**
   * Numerical value to display that should match the datapoint value
   */
  value: number;
  /**
   * Unit text for a datapoint value in the hover tooltip
   */
  unit: string;
  /**
   * Optional additional text description of a datapoint
   */
  description?: string | HTMLSpanElement;
  chartType?: ChartLayerSettings["type"];
  chartShape?: ChartLayerSettings["shape"];
  dashedLine?: ChartLayerSettings["dashedLine"];
  additionalInfo?: LabelWithDescription;
};

type TooltipValueParams = {
  /**
   * string key matching the charted variable
   */
  [dataKey: string]: {
    /**
     * Optional hex color string for the tooltip text
     */
    color?: string;
    /**
     * Optional mask for the tooltip text
     */
    mask?: string;
    /**
     * Optional string text label
     */
    dataLabel?: string;
    /**
     * Optional boolean whether the value should be visible
     */
    hidden?: boolean;
    /**
     * Value fomratter - layer specific
     */
    tooltipValueFormatter?: TooltipValueFormatter;
  };
};

type ViewBox = {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  brushBottom?: number;
};

type CustomTooltipProps = {
  unit?: string;
  valueParams: TooltipValueParams;
  valueFormatter: TooltipValueFormatter;
  layers: Array<ChartLayerSettings>;
  showTotal: boolean;
  locale: string;
  referenceAreaProps?: Array<CustomReferenceAreaProps>;
  xKey?: string;
  xAxisProps?: XAxisProps;
  data?: Array<DataItem>;
  titleFormatter?: TooltipTitleFormatter;
  viewBox?: ViewBox;
} & Omit<TooltipProps<string | number, string>, "viewBox">;

type WithPortalProps = {
  active?: CustomTooltipProps["active"];
  viewBox?: CustomTooltipProps["viewBox"];
  coordinate?: CustomTooltipProps["coordinate"];
  onTooltipActive?: (active: boolean) => void;
};

const UNKNOWN_STACK_ID = "unknown-stack-id";
const REF_AREAS_STACK_ID = "ref-areas-stack-id";

const defaultTooltipTitleFormatter = (value: string | number) => value;

// make sure that specific item will be at the beginning of the array
const startArrayWithItem = (items: string[], firstItem: string) => {
  return items && items.length
    ? sortBy(items, (item) => (item === firstItem ? 0 : 1))
    : [];
};

const printValueWithUnit = (
  value: string | number,
  unit: string,
  locale: string
) => {
  return unit === "$" ? (
    <>
      <span className="ps-1 fw-medium">
        {typeof value === "number"
          ? getLocaleUSDCurrency(locale, value)
          : value}
      </span>
    </>
  ) : (
    <>
      <span className="fw-medium">{value}</span>
      <small className="ps-1">{unit}</small>
    </>
  );
};

const defaultValueFormatter: TooltipValueFormatter = (value, locale) =>
  typeof value === "number" && locale ? getLocaleNumber(locale, value) : value;

function CustomTooltip(props: CustomTooltipProps) {
  const {
    active,
    payload,
    label,
    unit,
    valueParams,
    valueFormatter = defaultValueFormatter,
    layers,
    showTotal = true,
    locale,
    referenceAreaProps,
    xKey,
    xAxisProps,
    data,
    titleFormatter,
  } = props;

  const tooltipTitleFormatter =
    titleFormatter || xAxisProps?.tickFormatter || defaultTooltipTitleFormatter;

  const tooltipRefAreas =
    referenceAreaProps && referenceAreaProps.length > 0
      ? referenceAreaProps.filter((refArea: CustomReferenceAreaProps) => {
          const { hideInTooltip, referenceArea } = refArea;
          if (hideInTooltip) {
            return false;
          }

          let refX1 = referenceArea.x1;
          let refX2 = referenceArea.x2;
          let currX = label;

          if (xAxisProps?.type !== "number" && xKey) {
            refX1 = findIndex(data, (item: DataItem) => item[xKey] === refX1);
            refX2 = findIndex(data, (item: DataItem) => item[xKey] === refX2);
            currX = findIndex(data, (item: DataItem) => item[xKey] === label);
          }

          return (
            (refX1 && !refX2 && currX >= refX1) ||
            (!refX1 && refX2 && currX <= refX2) ||
            (refX1 && refX2 && currX >= refX1 && currX <= refX2) ||
            (!refX1 && !refX2)
          );
        })
      : [];

  // group values by stackId
  const valueGroupsByStackId = useMemo(() => {
    return payload && Array.isArray(payload)
      ? payload.reduce((accGroups, payloadData) => {
          if (!payloadData.value && payloadData.value !== 0) {
            return accGroups;
          }
          const layer = layers.find(
            (layer: ChartLayerSettings) => layer.yKey === payloadData.dataKey
          );

          // find stackId of current item in the layers settings
          const payloadsDatastackId = layer?.stackId || UNKNOWN_STACK_ID;
          // if layer has unit defined, use that. If not, use the "global" chart unit
          const itemUnit = layer?.unit || unit;
          const itemDescription = layer?.description;

          // create new tooltip item, if it is visible
          const params =
            typeof payloadData?.dataKey === "string" ||
            typeof payloadData?.dataKey === "number"
              ? valueParams[payloadData.dataKey]
              : null;

          if (params && !params?.hidden) {
            // gather color, label and value for each dataset=line displayed in tooltip
            const newItem = {
              svgStyles: {
                fill: params.color || defaultColor,
                mask: params.mask,
                stroke: params.color || defaultColor,
              },
              label: params.dataLabel || payloadData.name,
              value: isNumber(payloadData.value)
                ? typeof params.tooltipValueFormatter === "function"
                  ? params.tooltipValueFormatter(payloadData.value)
                  : valueFormatter(payloadData.value, locale)
                : payloadData.value,
              unit: itemUnit,
              description: itemDescription,
              chartType: layer?.type,
              chartShape: layer?.shape,
              dashedLine: layer?.dashedLine,
            };
            // start the new group if it doesn't exist yet - initialize with empty array
            const groupsExtended = { ...accGroups };
            if (!groupsExtended[payloadsDatastackId]) {
              groupsExtended[payloadsDatastackId] = {
                items: [],
                total: 0,
                unit: itemUnit,
              };
            }
            groupsExtended[payloadsDatastackId].items.push(newItem);
            groupsExtended[payloadsDatastackId].total += isNumber(
              payloadData.value
            )
              ? payloadData.value
              : 0;
            return groupsExtended;
          } else {
            return accGroups;
          }
        }, {} as Record<string, any>)
      : {};
  }, [payload, layers, unit, valueParams, valueFormatter, locale]);

  if (tooltipRefAreas.length > 0) {
    valueGroupsByStackId[REF_AREAS_STACK_ID] = { items: [] };
    valueGroupsByStackId[REF_AREAS_STACK_ID].items.push(
      ...tooltipRefAreas.map((refArea: CustomReferenceAreaProps) => ({
        svgStyles: {
          fill: refArea.referenceArea.fill,
          strokeWidth: refArea.referenceArea.strokeWidth,
          strokeDasharray: refArea.referenceArea.strokeDasharray,
          stroke: refArea.referenceArea.stroke,
        },
        id: refArea.id,
        label: refArea.label,
        value: refArea.value,
        description: refArea.description,
        additionalInfo: refArea.additionalInfo,
      }))
    );
  }

  const isTooltipData =
    active &&
    payload &&
    payload.length &&
    Object.keys(valueGroupsByStackId).length > 0;

  return (
    <div className="bg-white shadow-sm py-4 x5-px-5 rounded-2">
      <div className="pb-2 fw-medium">{tooltipTitleFormatter(label, 0)}</div>

      {!isTooltipData && (
        <div className="tooltip__item-description text-secondary">No data.</div>
      )}
      {isTooltipData &&
        startArrayWithItem(
          startArrayWithItem(
            Object.keys(valueGroupsByStackId),
            UNKNOWN_STACK_ID
          ),
          REF_AREAS_STACK_ID
        ).map((stackId) => {
          return (
            <div key={stackId} className="tooltip__stack-group pt-2">
              {valueGroupsByStackId[stackId].items.map(
                (item: TooltipItemData) => (
                  <div
                    key={item.id || item.label}
                    className="tooltip__item py-1"
                  >
                    <div className="d-flex justify-content-between pb-1">
                      <div className="d-flex align-items-center">
                        {item.chartType === "line" ||
                        item.chartShape === "bar-line" ? (
                          <svg
                            className="me-2"
                            viewBox="0 0 16 2"
                            width="16"
                            height="16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <line
                              x1="1"
                              y1="0"
                              x2="15"
                              y2="0"
                              strokeWidth="2"
                              strokeDasharray={
                                item.dashedLine ? "6 2" : undefined
                              }
                              {...item.svgStyles}
                            />
                          </svg>
                        ) : (
                          <svg
                            className="me-2"
                            viewBox="0 0 16 16"
                            width="16"
                            height="16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="14" height="14" {...item.svgStyles} />
                          </svg>
                        )}

                        <span className="pe-2">{item.label}</span>
                      </div>
                      <div>
                        {printValueWithUnit(item.value, item.unit, locale)}
                      </div>
                    </div>
                    {item.description && (
                      <div
                        className={`tooltip__item-description text-secondary  ${
                          item.additionalInfo ? "pb-1" : ""
                        }`}
                      >
                        <small>{item.description as ReactNode}</small>
                      </div>
                    )}
                    {item.additionalInfo && (
                      <div className="tooltip__item-additional-info">
                        <span className="fs-6 text-secondary lh-sm">
                          {item.additionalInfo.label}
                        </span>
                        {item.additionalInfo.descriptionLines.map((line, i) => (
                          <small key={i}>{line}</small>
                        ))}
                      </div>
                    )}
                  </div>
                )
              )}
              {showTotal &&
                stackId !== UNKNOWN_STACK_ID &&
                stackId !== REF_AREAS_STACK_ID &&
                valueGroupsByStackId[stackId].items.length > 1 && (
                  <div className="d-flex justify-content-between pb-1">
                    <span className="pe-2">Total</span>
                    <span>
                      {printValueWithUnit(
                        valueFormatter(
                          valueGroupsByStackId[stackId].total,
                          locale
                        ),
                        valueGroupsByStackId[stackId].unit,
                        locale
                      )}
                    </span>
                  </div>
                )}
            </div>
          );
        })}
    </div>
  );
}

function withPortal<P, T extends WithPortalProps>(
  Component: React.ComponentType<P>
) {
  return function (props: T & P) {
    const { active, onTooltipActive, viewBox, coordinate } = props;

    const { refs, floatingStyles, update } = useFloating({
      open: active,
      placement:
        typeof viewBox?.width === "number" &&
        typeof viewBox?.left === "number" &&
        typeof coordinate?.x === "number" &&
        viewBox.width / 2 > coordinate.x - viewBox.left
          ? "right"
          : "left",
      // Make sure the tooltip stays on the screen
      whileElementsMounted: autoUpdate,
      middleware: [
        offset(20),
        flip({
          fallbackAxisSideDirection: "start",
          crossAxis: false,
        }),
        shift(),
      ],
    });

    useEffect(() => {
      // To udpate tooltip position when the page is overflowed,
      // also it smoothen the position update
      update();
    }, [coordinate?.x, coordinate?.y, update]);

    useEffect(() => {
      if (typeof onTooltipActive === "function") {
        onTooltipActive(!!active);
      }
    }, [active, onTooltipActive]);

    return (
      <>
        <div
          ref={refs.setReference}
          // Handle for tooltip reference, does not have to be visible to user
          aria-hidden="true"
          style={{
            width: "1px",
            height: "1px",
            visibility: "hidden",
          }}
        ></div>
        {active ? (
          <FloatingPortal>
            {/* NOTE: className recharts-wrapper required in order to correctly display colors in the tooltip if it's rendered in the portal (outside of original recharts-wrapper container) */}
            <div
              ref={refs.setFloating}
              className="recharts-wrapper"
              style={floatingStyles}
            >
              <Component {...props} />
            </div>
          </FloatingPortal>
        ) : null}
      </>
    );
  };
}

export const CustomTooltipWithPortal = withPortal(CustomTooltip);
