/** @format */

import React, { memo, useMemo } from "react";
import { TooltipIcon } from "@elements/TooltipIcon";
import { Icon } from "@elements/Icon";
import { ImageWithLoader } from "@elements/ImageWithLoader";
import {
  EnergyAsset,
  assetAcronymNameMap,
  AssetCapacity,
  getAssetCapacity,
  filterUniqueAssets,
} from "../types/assets";
import { Badge, Card } from "@bphxd/ds-core-react";
import { Star24 } from "@bphxd/ds-core-react/lib/icons";
export interface PortfolioCardProps {
  /**
   * url string for the card image
   */
  imgUrl: string;
  /**
   * name string for the card title
   */
  name: string;
  /**
   * address string
   */
  address: string;
  /**
   * list of energy asset type strings
   */
  assets: EnergyAsset[];
  /**
   * optional object entry for asset capacity records
   */
  assetCapacity?: AssetCapacity;
  /**
   * optional mapping for assets
   */
  assetsMapping?: Map<string, string>;
  /**
   * optional boolean value specifing, whether only unique assets should be shown
   */
  showOnlyUniqueAssets?: boolean;
  /**
   * string array of text labels to display in the card body
   */
  labels?: string[];
  /**
   * optional card onClick callback
   */
  onClick?: () => void;
  /**
   * option to set to compact row block format (for mobile)
   */
  compact?: boolean;
  /**
   * optional css style class
   */
  className?: string;
  /**
   * boolean if building is set as favorite
   */
  isFavorite?: boolean;
  /**
   * optional css style object
   */
  style?: React.CSSProperties;
  /**
   * optional boolean if submetered flag should display
   */
  submetered?: boolean;
  /**
   * Language used for number formating, defaults to user's browser language
   */
  locale?: string;
}

export const PortfolioCard = memo((props: PortfolioCardProps) => {
  const {
    imgUrl = "",
    name = "",
    address = "",
    assets = [],
    assetCapacity = {},
    assetsMapping = undefined,
    showOnlyUniqueAssets = false,
    labels = [],
    compact = false,
    isFavorite = false,
    onClick = () => null,
    className = "",
    style = {},
    submetered = false,
    locale = navigator.language,
  } = props;

  const processedAssets = useMemo(() => {
    return showOnlyUniqueAssets
      ? filterUniqueAssets(assets, assetsMapping)
      : assets;
  }, [assets, showOnlyUniqueAssets, assetsMapping]);

  return (
    <Card
      border
      hoverShadow
      tag="button"
      style={style}
      className={`portfolio-card border-primary position-relative w-100 h-100 ${
        compact ? "portfolio-card--compact" : ""
      } ${className}`}
      onClick={onClick}
      aria-label="Portfolio card"
    >
      <div className="card-img-wrapper">
        <ImageWithLoader alt={name} src={imgUrl} />
      </div>
      {!compact && submetered ? (
        <div className="position-absolute x5-ms-5 mt-5 start-0 top-0">
          <Badge color="dark">Submeter</Badge>
        </div>
      ) : null}
      <Card.Body className="w-100">
        <div className="d-flex">
          <Card.Title
            className={`text-break ${isFavorite ? "pe-2" : ""}`}
            aria-label="portfolio-name"
          >
            {name}
          </Card.Title>
          {isFavorite ? <Star24 className="mt-n1 flex-shrink-0" /> : null}
        </div>

        {address ? (
          <Card.Subtitle className="mb-3" aria-label="portfolio-address">
            {address}
          </Card.Subtitle>
        ) : null}

        <div className="d-flex flex-wrap align-items-start justify-content-between gap-3">
          {labels.length ? (
            <div
              className="d-flex flex-wrap gap-3"
              aria-label="portfolio-labels"
            >
              {compact && submetered ? (
                <Badge color="dark">Submeter</Badge>
              ) : null}
              {labels.map((label, index) => (
                <Badge
                  key={index}
                  role="listitem"
                  style={
                    label.includes("kWh")
                      ? { textTransform: "none" } // to keep unit formatting
                      : undefined
                  }
                >
                  {label}
                </Badge>
              ))}
            </div>
          ) : null}
          {processedAssets.length ? (
            <div className="d-flex flex-wrap gap-3" aria-label="Energy Mix">
              {processedAssets.map((originalAssetName, index) => {
                const asset: EnergyAsset = assetsMapping
                  ? (assetsMapping.get(originalAssetName) as EnergyAsset) ||
                    originalAssetName
                  : originalAssetName;

                return (
                  <TooltipIcon
                    key={index}
                    icon={<Icon name={asset} size={24} />}
                    label={assetAcronymNameMap.get(asset)}
                    title={
                      <span>
                        {assetAcronymNameMap.get(asset)}
                        <br />
                        {getAssetCapacity(
                          assetCapacity,
                          originalAssetName,
                          locale
                        )}
                      </span>
                    }
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      </Card.Body>
    </Card>
  );
});
