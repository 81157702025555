/** @format */

import React, { memo, useMemo } from "react";
import {
  EnergyAsset,
  assetAcronymNameMap,
  AssetCapacity,
  getAssetCapacity,
  filterUniqueAssets,
} from "../types/assets";
import { ImageWithLoader } from "@elements/ImageWithLoader";
import { getLocaleNumber } from "src/lib/utils";
import {
  Clock16,
  ExternalLink16,
  MapPin16,
  Right16,
  Star24,
} from "@bphxd/ds-core-react/lib/icons";
import { Badge, Button, Tooltip } from "@bphxd/ds-core-react";
import { TooltipIcon } from "@elements/TooltipIcon";
import { Icon } from "@elements/Icon";
import { uniqueId } from "lodash-es";

export interface Consumption {
  total: number;
  totalLabel?: string;
}

export type PortfolioDetailLayout = "row" | "column";

export type ButtonLink = {
  label: string;
  to: string;
  external?: boolean;
  onClick?: Function;
};
export interface PortfolioDetailCardProps {
  /**
   * card image url
   */
  imgUrl: string;
  /**
   * name / title text
   */
  name: string;
  /**
   * address text
   */
  address: string;
  /**
   * boolean either the building is marked as favorite or not
   */
  isFavorite?: boolean;
  /**
   * callback fired on favorite icon click
   */
  onFavorite?: () => void;
  /**
   * consumption object containing total consumption
   */
  consumption: Consumption;
  /**
   * optional timezone specification e.g. "America/New_York"
   */
  timezone?: string;
  /**
   * optional array of energy assets
   */
  assets?: EnergyAsset[];
  /**
   * optional array of asset objects with energy and power capacity values
   */
  assetCapacity?: AssetCapacity;
  /**
   * optional mapping for assets
   */
  assetsMapping?: Map<string, string>;
  /**
   * optional boolean value specifing, whether only unique assets should be shown
   */
  showOnlyUniqueAssets?: boolean;
  /**
   * optional css style object
   */
  style?: React.CSSProperties;
  /**
   * optional layout string specification "row" or "column"
   */
  layout?: PortfolioDetailLayout;
  buttonLinks?: Array<ButtonLink>;
  /**
   * Language used for number formating, defaults to user's browser language
   */
  locale?: string;
  hasSubmetering?: boolean;
}

export const PortfolioDetailCard = memo((props: PortfolioDetailCardProps) => {
  const {
    imgUrl,
    name,
    address,
    hasSubmetering = false,
    assets = [],
    assetCapacity = {},
    assetsMapping = undefined,
    showOnlyUniqueAssets = false,
    isFavorite,
    onFavorite = () => null,
    style = {},
    consumption,
    timezone,
    layout = "row",
    buttonLinks,
    locale = navigator.language,
  } = props;

  const processedAssets = useMemo(() => {
    return showOnlyUniqueAssets
      ? filterUniqueAssets(assets, assetsMapping)
      : assets;
  }, [assets, showOnlyUniqueAssets, assetsMapping]);

  const favoriteTooltipId = useMemo(() => uniqueId("favorites-tooltip"), []);

  const content = useMemo(
    () => (
      <>
        <h1
          className={`text-break line-clamp-2 flex-shrink-0 fs-5 mb-0 ${
            isFavorite !== undefined ? " pe-8" : ""
          }`}
          title={name}
          role="heading"
          aria-level={1}
        >
          {name}
        </h1>
        {isFavorite !== undefined && (
          <>
            <Button
              size="sm"
              level={isFavorite ? "primary" : "quartenary"}
              id={favoriteTooltipId}
              onClick={onFavorite}
              rounded="circle"
              className="position-absolute top-0 end-0 mt-4 x5-me-5"
              iconOnly
              aria-label={
                isFavorite ? "Remove from favorites" : "Add to favorites"
              }
              Icon={Star24}
            />
            <Tooltip fade={false} target={favoriteTooltipId} offset={[0, 0]}>
              {isFavorite ? "Remove from favorites" : "Add to favorites"}
            </Tooltip>
          </>
        )}
        <div className="d-flex flex-column gap-3">
          <div className="d-flex gap-3 fs-6 lh-sm text-body">
            <MapPin16 className="text-secondary flex-shrink-0" />
            <address
              title={address}
              className="line-clamp-3 mb-0"
              aria-label="Address"
            >
              {address}
            </address>
          </div>
          {timezone && (
            <div className="d-flex gap-3 fs-6 lh-sm text-body">
              <Clock16 className="text-secondary flex-shrink-0" />
              <span title="Time Zone">{timezone}</span>
            </div>
          )}
        </div>
        <div
          className={`d-flex flex-grow-1 justify-content-between ${
            layout === "column" ? "" : "flex-wrap"
          }`}
        >
          <div
            className={`d-flex  gap-4 ${
              layout === "column"
                ? "flex-column"
                : "flex-row justify-content-between"
            } flex-wrap`}
          >
            {consumption && (
              <div>
                <div className="lh-sm text-secondary fs-6 fw-light">
                  {consumption.totalLabel ||
                    "Electricity Consumption (this month)"}
                </div>
                <span
                  className="fw-medium lh-sm"
                  title="Electricity consumption for this month"
                  aria-label="Electricity consumption for this month"
                >
                  {getLocaleNumber(locale, consumption.total)} kWh
                </span>
              </div>
            )}
            <div>
              <div className="lh-sm text-secondary fs-6 fw-light text-nowrap pb-2">
                On-site Energy Mix
              </div>
              <div className="d-flex flex-wrap gap-3">
                {processedAssets.map((originalAssetName, index) => {
                  const asset: EnergyAsset = assetsMapping
                    ? (assetsMapping.get(originalAssetName) as EnergyAsset) ||
                      originalAssetName
                    : originalAssetName;

                  const assetAcronym = assetAcronymNameMap.get(asset);

                  return (
                    <TooltipIcon
                      key={`${assetAcronym}-${index}`}
                      icon={<Icon name={asset} size={24} />}
                      label={assetAcronym}
                      title={
                        <span>
                          {assetAcronym}
                          <br />
                          {getAssetCapacity(
                            assetCapacity,
                            originalAssetName,
                            locale
                          )}
                        </span>
                      }
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {buttonLinks && buttonLinks.length > 0 && (
          <div className="flex-1 d-flex justify-content-end align-items-end gap-3">
            {buttonLinks.map((button, btnIndex) => {
              const { external, label, to, onClick = () => null } = button;

              const buttonProps = external
                ? {
                    href: to,
                    target: "_blank",
                  }
                : {
                    onClick: () => onClick(to),
                  };

              return (
                <Button
                  key={btnIndex}
                  size="sm"
                  rounded="0"
                  level="secondary"
                  iconPosition="end"
                  Icon={external ? ExternalLink16 : Right16}
                  {...buttonProps}
                >
                  {label}
                </Button>
              );
            })}
          </div>
        )}
      </>
    ),
    [
      isFavorite,
      name,
      favoriteTooltipId,
      onFavorite,
      address,
      timezone,
      layout,
      consumption,
      locale,
      processedAssets,
      buttonLinks,
      assetsMapping,
      assetCapacity,
    ]
  );

  return (
    <section
      aria-label="Portfolio detail card"
      style={style}
      className={`portfolio-detail-card shadow-sm h-100 p-0 ${
        layout === "column"
          ? "d-flex align-items-stretch"
          : "d-flex flex-column"
      }`}
    >
      <div className="portfolio-detail-card__image position-relative w-100 flex-grow-1">
        <ImageWithLoader
          src={imgUrl}
          alt={name}
          className="h-100 w-100 position-absolute"
        />
        {hasSubmetering ? (
          <Badge
            color="dark"
            className="position-absolute top-0 right-0 x5-ms-5 mt-4"
          >
            Submeter
          </Badge>
        ) : null}
      </div>
      <div className="x5-px-5 py-4 bg-white d-flex flex-column gap-4 w-100 position-relative">
        {content}
      </div>
    </section>
  );
});
